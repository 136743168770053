<template>
	<div class="w-full flex justify-center items-center">
		<div class="max-w-[480px] mx-auto w-full py-12">
			<div class="text-center">
				<nuxt-link :to="localePath('/')" class="inline-block">
					<img class="h-10 md:h-20 w-auto" src="~/assets/img/logo_sirius.svg" alt="Logo" />
				</nuxt-link>
				<h1 class="mt-6 font-bold text-3xl lg:text-4xl text-center text-black">{{ $t("login.sign_in") }}</h1>
				<p class="text-gray-500 mt-3 text-xl">
					{{ $t("login.or") }}
					<nuxt-link :to="localePath('/register')" class="!text-primary-500 hover:underline">
						{{ $t("login.create") }}
					</nuxt-link>
				</p>
			</div>
			<div class="bg-white px-4 sm:px-6 pt-10 pb-7 shadow mt-6">
				<v-form class="form_login">
					<v-text-field outline label="Email Address" type="text" v-model="credentials.login"></v-text-field>
					<v-text-field outline hide-details :label="$t('login.password_label')" type="password" v-model="credentials.password"></v-text-field>
				</v-form>

				<div class="flex items-center justify-between mb-5 mt-10">
					<v-checkbox class="text-primary-500" v-model="credentials.rememberMe" label="Remember me"></v-checkbox>
					<nuxt-link :to="localePath('/forgotpassword')" class="!text-primary-500">Forgot Password</nuxt-link>
				</div>
				<button @click="login" class="primary-btn !bg-primary-500 w-full hover:!bg-black transition-all ease-in-out">{{ $t("login.submit") }}</button>
				<v-progress-linear v-if="isLoading" indeterminate color="cyan"></v-progress-linear>
			</div>
		</div>

		<v-snackbar v-model="errors">
			{{ $t("login.error") }}
		</v-snackbar>
	</div>
</template>
<style>
.form_login .v-input__slot {
	margin-bottom: 30px;
}
.form_login .v-text-field input {
	padding: 12px 0;
}
.form_login .v-input input {
	max-height: 100px !important;
}
</style>
<script>
export default {
	layout: "page",
	name: "login",
	head() {
		return {
			title: this.$t("login.meta.name"),
			meta: [
				{ charset: "utf-8" },
				{ name: "viewport", content: "width=device-width, initial-scale=1" },
				{
					hid: "description",
					name: "description",
					content: this.$t("login.meta.description")
				}
			],
			link: [{ rel: "icon", type: "image/x-icon", href: "/favicon.ico" }]
		};
	},
	data() {
		return {
			isLoading: false,
			credentials: {
				login: "wp@hellenictechnologies.com",
				password: "(csG6NHt!wtt!n(FwMtd(T5j",
				rememberMe: false
			},
			errors: false
		};
	},
	methods: {
		async login() {
			this.isLoading = true;
			try {
				const { loginWithCookies: data } = await this.$services.auth.login(this.$apollo, this.credentials).then(res => res.data);
				if (data.status == "SUCCESS") {
					this.$router.push(this.localePath("/profile"));
				}
			} catch (e) {
				this.errors = true;
			} finally {
				this.isLoading = false;
			}
		}
	}
};
</script>
